var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_vm.reportingAlertText ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.reportingAlertText) + " ")]) : _vm._e(), _vm.bottlingAlertText ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.bottlingAlertText) + " ")]) : _vm._e(), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('h5', [_vm._v(" Alcohol Finishing Action " + _vm._s(_vm.mode === _vm.$constants.FORM_MODE.CREATE ? 'Creation' : '#' + this.id) + " "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.finished_product_number.id,
      "value": _vm.data.finished_product_number,
      "label": "Finished Product Number",
      "readonly": true,
      "mode": _vm.mode
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.finished_product.id,
      "value": _vm.data.finished_product.id,
      "label": "Finished Product ID",
      "readonly": true,
      "mode": _vm.mode,
      "required": _vm.controls.finished_product.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "5",
      "sm": "5"
    }
  }, [_c('inline-select', {
    ref: "finished-product",
    attrs: {
      "id": _vm.controls.finished_product.id,
      "value": _vm.data.finished_product,
      "label": _vm.controls.finished_product.label,
      "readonly": _vm.controls.finished_product.readonly,
      "options": _vm.controls.finished_product.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true,
      "group_values": _vm.controls.finished_product.group_values,
      "group_label": _vm.controls.finished_product.group_label
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    staticStyle: {
      "align-self": "center"
    },
    attrs: {
      "cols": "1"
    }
  }, [_vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('button', {
    staticClass: "btn btn-success btn-sm",
    on: {
      "click": function click($event) {
        return _vm.editFinishedProduct();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  })], 1) : _vm._e()]), _c('b-col', {
    attrs: {
      "lg": "5",
      "md": "6",
      "sm": "12"
    }
  }, [_vm.data.finished_product.id !== '' ? _c('inline-select', {
    attrs: {
      "id": _vm.controls.container.id,
      "value": _vm.data.container,
      "label": _vm.controls.container.label,
      "readonly": _vm.controls.container.readonly,
      "options": _vm.controls.container.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true,
      "custom-option-template": true
    },
    on: {
      "changed": _vm.updateSelect
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(props) {
        return _c('div', {}, [props.slotScope.option && props.slotScope.option.id ? _c('span', [_vm._v(" Container #" + _vm._s(props.slotScope.option.id) + " [Free " + _vm._s(props.slotScope.option.freeSpace) + "L of " + _vm._s(props.slotScope.option.size) + "L]")]) : _vm._e()]);
      }
    }, {
      key: "option",
      fn: function fn(props) {
        return _c('div', {}, [_c('b-container', {
          attrs: {
            "no-glutters": ""
          }
        }, [_c('hr', {
          staticStyle: {
            "margin-top": "0px",
            "padding-top": "0px"
          }
        }), _c('span', [_vm._v(" Container #" + _vm._s(props.slotScope.option.id) + " [Free " + _vm._s(props.slotScope.option.freeSpace) + "L of " + _vm._s(props.slotScope.option.size) + "L] ")]), props.slotScope.option.items && props.slotScope.option.items.length ? _c('b-table-simple', {
          staticStyle: {
            "width": "300px",
            "margin-top": "1em",
            "font-size": "0.7rem"
          },
          attrs: {
            "small": "",
            "bordered": "",
            "responsive": ""
          }
        }, [_c('b-tbody', _vm._l(props.slotScope.option.items, function (item, index) {
          return _c('b-tr', {
            key: "copt-".concat(index)
          }, [_c('b-td', [_vm._v(_vm._s(item.name))]), _c('b-td', [_vm._v(_vm._s(item.amount) + "L")])], 1);
        }), 1)], 1) : _vm._e()], 1)], 1);
      }
    }], null, false, 3407804056)
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "12"
    }
  }, [_vm.data.finished_product.id !== '' ? _c('inline-select', {
    attrs: {
      "id": _vm.controls.af_source.id,
      "value": _vm.data.af_source,
      "label": _vm.controls.af_source.label,
      "readonly": _vm.controls.af_source.readonly,
      "options": _vm.controls.af_source.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateAfSource
    }
  }) : _vm._e()], 1)], 1), _vm.data.af_source ? _c('b-row', [_c('b-col', [_c('hr'), _c('alcohol-finishing-items-table', {
    ref: "af-items",
    attrs: {
      "af-id": _vm.id,
      "product-id": _vm.data.finished_product.id,
      "af-source-id": _vm.data.af_source.id,
      "mode": _vm.mode
    },
    on: {
      "updated": _vm.onItemsTableUpdate,
      "inserted": _vm.onItemsTableInsert,
      "deleted": _vm.onItemsTableDelete
    }
  })], 1)], 1) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.af_date.id,
      "value-single": _vm.data.af_date,
      "label": _vm.controls.af_date.label,
      "readonly": _vm.controls.af_date.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.af_date.required
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.final_amount.id,
      "value": _vm.data.final_amount,
      "label": _vm.controls.final_amount.label,
      "readonly": _vm.controls.final_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.final_amount.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.container_alcohol_kg.id,
      "value": _vm.data.container_alcohol_kg,
      "label": _vm.controls.container_alcohol_kg.label,
      "readonly": _vm.controls.container_alcohol_kg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.container_alcohol_kg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.container_weight.id,
      "value": _vm.data.container_weight,
      "label": _vm.controls.container_weight.label,
      "readonly": _vm.controls.container_weight.readonly,
      "options": _vm.controls.container_weight.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.actual_alcohol_kg.id,
      "value": _vm.data.actual_alcohol_kg,
      "label": _vm.controls.actual_alcohol_kg.label,
      "readonly": _vm.controls.actual_alcohol_kg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.actual_alcohol_kg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.actual_product_mas.id,
      "value": _vm.data.actual_product_mas,
      "label": _vm.controls.actual_product_mas.label,
      "readonly": _vm.controls.actual_product_mas.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.actual_product_mas.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.wanted_product_mas.id,
      "value": _vm.data.wanted_product_mas,
      "label": _vm.controls.wanted_product_mas.label,
      "readonly": _vm.controls.wanted_product_mas.readonly,
      "options": _vm.controls.wanted_product_mas.options,
      "allow-empty": false,
      "multiple": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.wanted_product_percentage.id,
      "value": _vm.data.wanted_product_percentage,
      "label": _vm.controls.wanted_product_percentage.label,
      "readonly": _vm.controls.wanted_product_percentage.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.wanted_product_percentage.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.water_amount_kg.id,
      "value": _vm.data.water_amount_kg,
      "label": _vm.controls.water_amount_kg.label,
      "readonly": _vm.controls.water_amount_kg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.water_amount_kg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.water_amount_l.id,
      "value": _vm.data.water_amount_l,
      "label": _vm.controls.water_amount_l.label,
      "readonly": _vm.controls.water_amount_l.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.water_amount_l.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.alcohol_amount_l.id,
      "value": _vm.data.alcohol_amount_l,
      "label": _vm.controls.alcohol_amount_l.label,
      "readonly": _vm.controls.alcohol_amount_l.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.alcohol_amount_l.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_finished_total_kg.id,
      "value": _vm.data.amount_finished_total_kg,
      "label": _vm.controls.amount_finished_total_kg.label,
      "readonly": _vm.controls.amount_finished_total_kg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_finished_total_kg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_finished_total_l.id,
      "value": _vm.data.amount_finished_total_l,
      "label": _vm.controls.amount_finished_total_l.label,
      "readonly": _vm.controls.amount_finished_total_l.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_finished_total_l.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_finished_total_pg.id,
      "value": _vm.data.amount_finished_total_pg,
      "label": _vm.controls.amount_finished_total_pg.label,
      "readonly": _vm.controls.amount_finished_total_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_finished_total_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_finished_total_wg.id,
      "value": _vm.data.amount_finished_total_wg,
      "label": _vm.controls.amount_finished_total_wg.label,
      "readonly": _vm.controls.amount_finished_total_wg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_finished_total_wg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.status.id,
      "value": _vm.data.status,
      "label": _vm.controls.status.label,
      "readonly": _vm.controls.status.readonly,
      "options": _vm.controls.status.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.filtered.id,
      "value": _vm.data.filtered,
      "label": _vm.controls.filtered.label,
      "readonly": _vm.controls.filtered.readonly,
      "options": _vm.controls.filtered.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": _vm.controls.filtered.required
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.remaining_amount.id,
      "value": _vm.data.remaining_amount,
      "label": _vm.controls.remaining_amount.label,
      "readonly": _vm.controls.remaining_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.remaining_amount.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('inline-input', {
    attrs: {
      "id": _vm.controls.finishing_notes.id,
      "value": _vm.data.finishing_notes,
      "label": _vm.controls.finishing_notes.label,
      "readonly": false,
      "mode": _vm.mode,
      "rows": 3,
      "required": false,
      "is-text-area": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created_by.id,
      "value": _vm.data.created_by,
      "label": _vm.controls.created_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_by.id,
      "value": _vm.data.modified_by,
      "label": _vm.controls.modified_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Alcohol finishings'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Alcohol finishing submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Alcohol finishing submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.bottling.title
    }
  }, [_c('bottling-table', {
    ref: "bottling-table",
    attrs: {
      "alcohol-finishing-id": _vm.id
    },
    on: {
      "loaded": _vm.onBottlingTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.barrels.title
    }
  }, [_c('barrels-table', {
    ref: "barrels-table",
    attrs: {
      "alcohol-finishing-id": _vm.id
    },
    on: {
      "loaded": _vm.onBarrelsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.alcohol_finishings.title
    }
  }, [_c('alcohol-finishing-table', {
    key: "alcohol-finishing-table-".concat(_vm.id),
    ref: "alcohol-finishing-table",
    attrs: {
      "parent-action-id": _vm.id
    },
    on: {
      "loaded": _vm.onAlcoholFinishingTableLoad
    }
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }